.titleHandlerOnama {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-382%, -29%) !important;
    z-index: 1 !important;
    color: #fff !important;
  }

@media (max-width: 576px) {
    .h3TapaciraniNamestaj {
      font-size: 27px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerOnama {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-120%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    }
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 577px) and (max-width: 768px) { 
    .h3TapaciraniNamestaj {
      font-size: 28px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerOnama {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-89%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .h3TapaciraniNamestaj {
      font-size: 30px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerOnama {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-235%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 993px) and (max-width: 1200px) {
    .h3TapaciraniNamestaj {
      font-size: 30px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerOnama {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-325%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 1201px) and (max-width: 1462px) {
    .h3TapaciraniNamestaj {
      font-size: 32px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerOnama {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-395%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
  }

  .colG-3 {
    width: 50%;
  }
  
  
  .colG-2 {
    width: 33.333%;
  }

  @media (max-width: 988px) {
    .colG-2 {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    .colG-2 {
      width: 100%;
    }
    .colG-3 {
        width: 100%;
      }
}

@media (max-width: 480px) {
    
    .colG-2 {
      width: 100%;
    }
    .colG-3 {
        width: 100%;
      }
}

.wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  @media (max-width: 600px) {
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .flex-contact {
    display: flex;
}
  .flex-contact1 {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  
.con-style {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .conp1 {
    text-decoration: none;
    color: #000 !important;
    cursor: pointer;
  }

  .colG-2 {
    width: 33.333%;
  }

  @media (max-width: 988px) {
    .colG-2 {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    
    .colG-2 {
      width: 100%;
    }
}

@media (max-width: 480px) {
    .colG-2 {
      width: 100%;
    }
}

.colG-6 {
    width: 100%;
  }

  .h2con {
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
  }
  

  .h2con2 {
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
  }

  .conp {
    font-size: 18px;
    margin-top: 35px;
    text-align: left;
    color: #2f3542;
  }

  .pelite {
    font-size: 25px;
    margin-top: 35px;
    text-align: left;
    font-weight: bold;
    color: #2f3542;
  }
  
  .conp1 {
    text-decoration: none;
    color: #000 !important;
    cursor: pointer;
  }
  

  @media (max-width: 370px) {
    .h2con {
      font-size: 34px !important;
    }
    .h2con2 {
      font-size: 34px !important;
    }
  }

  .conhr {
    color: #2f3542;
    border-top: 2px solid;
    width: 350px;
    margin: 15px 0 0 0;
    opacity: 1;
  }

  .googlemap {
    width: 100% !important;
    border: none !important;
  }

  .map {
    padding-top: 30px;
    background-color: inherit;
  }
  .hrProizvodi {
    color: #2f3542;
    border-top: 2px solid;
    width: 350px;
    margin: 15px 0 0 0 ;
    opacity: 1;
    
}

.h2Proizvodi {    
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
}