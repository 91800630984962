.pagination {
    --bs-pagination-padding-x: 1rem !important;
    --bs-pagination-padding-y: 0.60rem !important;
    --bs-pagination-bg: #fff !important;
    --bs-pagination-color: #2f3542 !important;
    --bs-pagination-border-width: 1px !important;
    --bs-pagination-border-radius: 0rem !important;
    --bs-pagination-hover-bg: #2f3542 !important;
    --bs-pagination-border-color: #2f3542 !important;
    --bs-pagination-active-border-color: #2f3542 !important;
    --bs-pagination-active-bg: #2f3542 !important;
    --bs-pagination-focus-color: #fff !important;
    --bs-pagination-focus-bg: #2f3542 !important;
    --bs-pagination-focus-box-shadow: 0px !important;
    --bs-pagination-disabled-bg: #2f3542 !important;
    padding-left: 10px !important;
}

.pagination-item {
    padding-right: 5px !important;
}