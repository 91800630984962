.bg-dark {
    background-color: transparent !important;
}

.fixed-top {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.bg {
    /* The image used */
    /* background-image: url('industrie-header-min-ndz3wvv9aoggll566m6qmn49o65q1snpmwf1y6vase.jpg'); */
    background: linear-gradient(
        rgba(53, 53, 53, 0.427),
        rgba(58, 58, 58, 0.4)
      );
  }

  .bgNamestaj {
    background: #2f3542 !important;
  }

  .aNamestaj:hover {
    color: #fff !important;
}
  
  .carousel-item-bg {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
  

.carousel-item {
    height: 100vh;
    min-height: 300px;
    object-fit: cover;
}

.carousel-caption {
    bottom: 10em;
}

.carousel-caption h3 {
    font-size: 45px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 25px;
}

.carousel-caption p {
    width: 60%;
    margin: auto;
    font-size: 18px;
    line-height: 1.9;
}

.btn_carousel {
    text-transform: uppercase;
    text-decoration: none;
    background: darkorange;
    padding: 11px 17px;
    color: #111;
    border: 2px solid darkorange;
    border-radius: 1;
    margin-top: 15px;
    transition: all 0.4s;
}
.btn_carousel:hover {
    background: transparent;
    border: 2px solid white;
}
.btn_carousel:active {
    background: transparent !important;
    border: 2px solid white !important;
}
.btn_carousel:focus-visible {
    background: transparent !important;
    border: 2px solid white !important;
    box-shadow: none !important;
}

 /* #2f3542 !important */
.navbar-nav a {
    font-size: 18px;
    font-weight: 700;
}

.navbar-nav-header:hover{
    color: darkorange !important;
}

.navbar-dark .navbar-brand {
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

.w-100 {
    height: 100vh;
    min-height: 300px;
    object-fit: cover;
}

.navbar-toggler {
    padding: 1px 5px;
    font-size: 21px;
    line-height: 0.3;
    border: 2px solid;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0;
}

/* @media only screen and (max-width: 767px) {
    .navbar-nav {
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
    }
    .carousel-caption {
        bottom: 165px;
    }
    .carousel-caption h3 {
        font-size: 17px;
    }
    .carousel-caption a {
        padding: 10px 15px;
    }
} */

.logo {
    width: 250px;
    height: 100%;
}

@media (max-width: 576px) {
    /* CSS pravila za extra small ekrane */
    .carousel-caption {
        bottom: 17em;
    }
    .logo {
        width: 250px;
        height: 100%;
    }

    .btn_carousel {
        font-size: 12px;
    }


    .carousel-caption h3 {
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 25px;
    }
    .carousel-item {
        height: 50vh;
        min-height: 300px;
        object-fit: cover;
    }
    .w-100 {
        height: 50vh;
        min-height: 300px;
        object-fit: cover;
    }
    .sliderh4 {
        font-size: 17px !important;
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    /* CSS pravila za small ekrane */
    .carousel-caption {
        bottom: 300px;
    }
    .logo {
        width: 250px;
        height: 100%;
    }  
}

  @media (min-width: 769px) and (max-width: 992px) {
    /* CSS pravila za medium ekrane */
    .carousel-caption p {
        width: 80%;
        margin: auto;
        font-size: 20px;
        line-height: 1.9;
    }
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    /* CSS pravila za large ekrane */
  }

  @media (min-width: 1201px) {
    /* CSS pravila za extra large ekrane */
    .carousel-caption p {
        width: 100%;
        margin: auto;
        font-size: 18px;
        line-height: 1.9;
    }
}


  
a:focus,
a:focus-visible {
  outline: none;
}

.headerNam-nav-link {
    color: #fff !important;
}

.headerNam-nav-link:hover {
    color: darkorange !important;
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: center;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 20px 74px 20px 74px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}

#logo {
    width: 250px;
    height: 100%;
}

#navbarnew {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

#navbarnew li {
    list-style: none;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
}

#navbarnew li p {
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    transition: 0.3s ease-in-out;
    margin: 0;
}

#navbarnew li p:hover,
#navbarnew li p.active  {
    color: darkorange;
}

#navbarnew li p:hover::after,
#navbarnew li p.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background-color: darkorange;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile {
    display: none;
}

.linkWraper {
    color: darkorange;
    text-decoration: none;
}

.linkWraper:hover {
    color: darkorange;
}

#mobile i {
    color: #fff;
}

@media screen and (max-width: 992px) {    
    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        padding: 20px 74px 20px 24px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    }

    #logo {
        width: 150px;
        height: 100%;
    }

    .sliderh4 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        color: #2f3542;
    }

    #navbarnew {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: -1500px;
        right: 0px;
        width: 100%;
        height: 100vh;
        background-color: #2f3542;
        z-index: 999;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbarnew li p:hover::after,
    #navbarnew li p.active::after {
    display: none;
    }

    #navbarnew.active {
        top: 0px;
    }

    #navbarnew li { 
        margin-bottom: 25px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

    .mobileClass { 
        position: absolute;
        right: 75px;
        top: 25px;
    }

    #namestajNav {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: fixed;
            top: 74px;
            right: -1500px;
            width: 100%;
            height: 100vh;
            background-color: #2f3542;
            z-index: 999;
            box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
            padding: 40px 0 0 10px;
            transition: 0.3s ease-in-out;
    }

    #namestajNav.active {
        right: 0px;
    }
    
    #tapaciraniNav {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: fixed;
            top: 74px;
            right: -1500px;
            width: 100%;
            height: 100vh;
            background-color: #2f3542;
            z-index: 999;
            box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
            padding: 40px 0 0 10px;
            transition:  0.3s ease-in-out;
    }

    #tapaciraniNav.active {
        right: 0px;
    }

    #trpezarijeNav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        top: 74px;
        right: -1500px;
        width: 100%;
        height: 100vh;
        background-color: #2f3542;
        z-index: 999;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition:  0.3s ease-in-out;
    }

    #trpezarijeNav.active {
        right: 0px;
    }

    #spavaceNav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        top: 74px;
        right: -1500px;
        width: 100%;
        height: 100vh;
        background-color: #2f3542;
        z-index: 999;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition:  0.3s ease-in-out;
    }

    #spavaceNav.active {
        right: 0px;
    }
}

body.navigation-open {
    overflow: hidden;
}


  