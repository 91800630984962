.CardImgTrpezarija {
  width: 100%;
  height: 240px;
  transition: transform 0.5s ease-in-out;
}

.linkWraper {
  color: #2f3542;
  text-decoration: none;
}

.linkWraper:hover {
  color: #2f3542;
}
.pointer {
  cursor: pointer;
}

.enter-transition {
  transition: transform 1s ease-in-out;
}

.image-wrapper {
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.image-wrapper:hover .CardImgTrpezarija {
  transform: scale(1.1);
}
.cardBody {
  flex: 1 1 auto;
  padding: 5px 0 0 0 !important;
  color: var(--bs-card-color);
}

.cardBody {
  transition: background-color 0.3s ease-in-out;
}

.cardBody:hover {
  background-color: #fff;
}

.trpezarijaProductCardImg {
  width: auto !important;
    height: 100%;
    object-fit: cover;
  }


.titleHandlerTrpezarije {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-270%, -29%) !important;
    z-index: 1 !important;
    color: #fff !important;
  }

@media (max-width: 576px) {
    .h3TapaciraniNamestaj {
      font-size: 27px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerTrpezarije {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-99%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    }
    .CardImgTrpezarija {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 577px) and (max-width: 768px) { 
    .h3TapaciraniNamestaj {
      font-size: 28px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerTrpezarije {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-89%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImgTrpezarija {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .h3TapaciraniNamestaj {
      font-size: 30px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerTrpezarije {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-164%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImgTrpezarija {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 993px) and (max-width: 1200px) {
    .h3TapaciraniNamestaj {
      font-size: 30px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerTrpezarije {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-235%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImgTrpezarija {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 1201px) and (max-width: 1462px) {
    .h3TapaciraniNamestaj {
      font-size: 32px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerTrpezarije {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-303%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
  }