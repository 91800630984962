.h3TapaciraniNamestaj {    
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #fff;
}

.imgFlex {
  display: flex;
  justify-content: center;
}

.mainImage {
    border-bottom-right-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
    border-top-left-radius: 0% !important;
    border-top-right-radius: 0% !important;
}
.card {
     --bs-card-border-width: 0px !important;
}

.cardImg {
    
    border-bottom-right-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
}

.cardBody {
    flex: 1 1 auto;
    padding: 5px 0 0 0 !important;
    color: var(--bs-card-color);
}
  

.navRoute {
    color: #2f3542;
    font-size: 17px;
    font-weight: 400;
}

.showResult {
    color: #2f3542;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.borderLeft {
    border-left: 1px solid #ced4da;
}

.checkboxFilter {
    transform: scale(1.3); /* Povećaj veličinu checkboxa */
    border: 2px solid red; /* Promijeni boju okvira u crvenu */
}

.searchButton {
    background-color: #2f3542 !important;
    color: #fff;
}

.form-control {
    border-radius: 0% !important;
}


.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: #fff;
    background-color: inherit;
    border: 0px solid white !important;
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: none !important;
}

.form-check-input:focus {
    border-color: #969fa8;
    outline: 0;
    box-shadow: none !important;
}

:focus-visible {
    outline: 0px !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #969fa8;
    outline: 0;
    box-shadow: none !important;
}

.Card {
    transition: transform 0.5s ease-in-out;
  }

  .pointer {
    cursor: pointer;
  }
  
  .Card:hover {
    transform: scale(1.1);
  }
  
  .cardBody {
    transition: background-color 0.3s ease-in-out;
  }
  
  .cardBody:hover {
    background-color: #fff;
  }
  
  .enter-transition {
    transition: transform 1s ease-in-out;
  }
  
  .CardImg {
    width: 100%;
    height: 190px;
    transition: transform 0.5s ease-in-out;
  }
  
  .image-wrapper {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .image-wrapper:hover .CardImg {
    transform: scale(1.1);
  }

.linkWraper {
    color: #2f3542;
    text-decoration: none;
}

.linkWraper:hover {
    color: #2f3542;
}

.slider {
    display: flex;
    overflow: hidden;
  }

  .slider-container {
    width: 100%; 
    max-height: 400px; 
    overflow: hidden; 
  }

  .slider-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  .slider-item {
    flex-shrink: 0;
    width: 100%;
    transition: transform 0.5s;
  }
  
  .slider-item.active {
    transform: translateX(0);
  }
  
  .thumbnail-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: -5px;
  }
  
  .thumbnail {
    padding: 0 5px;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .thumbnail.active {
    opacity: 0.7;
  }
  
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset,10px);
    right: auto;
    color: #2f3542 !important;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset,10px);
    left: auto;
    color: #2f3542 !important;
}

tr {
    height: 49.5px !important;
}
 
th {
    background: #2f3542 !important;
    color: #fff !important; 
}

.not-found {
  margin-bottom: 110px;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found img {
  width: 280px;
  height: 280px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100Vh;
}

/* SkeletonCard.css */
.skeleton-image{
  width: 100%;
  height: 180px; /* Podesite visinu skeleton slike prema vašim potrebama */
  background-color: #2f3542;
}

.skeleton-title {
  width: 100%;
  height: 40px; /* Podesite visinu skeleton slike prema vašim potrebama */
  background-color: #2f3542;
}

/* Stilizacija za "loading" stanje */
.loading .skeleton-image,
.loading .skeleton-title {
  animation: pulse 1.5s infinite; /* Dodajemo pulsirajuću animaciju */
}

/* Pulsirajuća animacija */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.titleHandler {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-140%, -29%) !important;
  z-index: 1 !important;
  color: #fff !important;
}


@media (max-width: 576px) {
  .h3TapaciraniNamestaj {
    font-size: 27px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #fff;
  }
  .titleHandler {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-89%, -15%) !important;
    z-index: 1 !important;
    color: #fff !important;
  }
  .CardImg {
    width: 100%;
    height: auto !important;
    transition: transform 0.5s ease-in-out;
  }
}


@media (min-width: 577px) and (max-width: 768px) { 
  .h3TapaciraniNamestaj {
    font-size: 28px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #fff;
  }
  .titleHandler {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-89%, -15%) !important;
    z-index: 1 !important;
    color: #fff !important;
  } 
  .CardImg {
    width: 100%;
    height: auto !important;
    transition: transform 0.5s ease-in-out;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .h3TapaciraniNamestaj {
    font-size: 30px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #fff;
  }
  .titleHandler {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-100%, -15%) !important;
    z-index: 1 !important;
    color: #fff !important;
  } 
  .CardImg {
    width: 100%;
    height: auto !important;
    transition: transform 0.5s ease-in-out;
  }
}


@media (min-width: 993px) and (max-width: 1200px) {
  .h3TapaciraniNamestaj {
    font-size: 30px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #fff;
  }
  .titleHandler {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-100%, -15%) !important;
    z-index: 1 !important;
    color: #fff !important;
  } 
  .CardImg {
    width: 100%;
    height: auto !important;
    transition: transform 0.5s ease-in-out;
  }
}


@media (min-width: 1201px) and (max-width: 1462px) {
  .h3TapaciraniNamestaj {
    font-size: 30px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #fff;
  }
  .titleHandler {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-147%, -15%) !important;
    z-index: 1 !important;
    color: #fff !important;
  } 
}

.image_slider_proizvodi {
  width: 100%;
  height: 190px;
  transition: transform 0.5s ease-in-out;
  margin-right: 15px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.mt-proizvodi {
  margin-top: 9rem;
}