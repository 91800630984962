.footerbg {
    background-color: #2f3542;
    display: block;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
}

.h5Footer {    
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #d1d1d1;
}

.h6FooterL {    
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: #d1d1d1;
}


.h6FooterC {    
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #d1d1d1;
}


.h6FooterR {    
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: right;
    color: #d1d1d1;
}


.pFooter {
    
    font-size: 16px;
    text-align: left;
    color: #d1d1d1;
}

.hrFooter {
    color: #d1d1d1;
    border-top: 1px solid;
    width: 410px;
    margin: 15px 0 15px 0 ;
    opacity: 1;
}

.hrFooterR {
    color: #d1d1d1;
    border-top: 1px solid;
    width: 371px;
    margin: 15px 0 15px 45px;
    opacity: 1;
}


.hrFooter1 {
    color: #d1d1d1;
    border-top: 1px solid;
    width: 100%;
    margin: 15px 0 15px 0 ;
    opacity: 1;
}

.hrFooterC {
    color: #d1d1d1;
    border-top: 1px solid;
    width: 210px;
    margin: 8px 0 15px 41px;
    opacity: 1;
}

.list-unstyledC {
    text-align: center;
    list-style: none;
    font-size: 16px;
    color: #d1d1d1;
    text-decoration: underline;
}

.list-unstyled {
    text-align: left;
    list-style: none;
    font-size: 16px;
    color: #d1d1d1;
}


.pointer {
    cursor: pointer;
}

.dFlexCenterHr {
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoHolder{
    position: relative;
}

.infoPosition {
    position: absolute;
    left: 46px;
}

.icons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .socialIcons {
    display: flex;
    gap: 10px; 
    margin-left: auto; 
  }
  
  .instagramIcon {
    color: #d1d1d1;
    font-size: 30px;
  }
  .pf {
    color: #d1d1d1;
    font-size: 16px;
  }

 

@media (max-width: 576px) {
    /* CSS pravila za extra small ekrane */
    .footerbg {
        background-color: #2f3542;
    }
    .h6FooterC {
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: left;
        color: #d1d1d1;
    }
    
    .dFlexCenterHr {
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .hrFooterC {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 410px;
        margin: 8px 0 15px 0px;
        opacity: 1;
    }
    .list-unstyledC {
        text-align: left;
        list-style: none;
        font-size: 16px;
        color: #d1d1d1;
        padding-left: 0;
        text-decoration: underline;
    }
    .hrFooterR {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 410px;
        margin: 15px 0 15px 0px;
        opacity: 1;
    }
    .infoPosition {
        position: relative;
        left: 0px;
    }
    .footerMT {
        margin-top: 3rem;
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    /* CSS pravila za small ekrane */
    .h6FooterC {
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: left;
        color: #d1d1d1;
    }
    
    .footerMT {
        margin-top: 3rem;
    }
    .dFlexCenterHr {
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .hrFooterC {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 8px 0 15px 0px;
        opacity: 1;
    }
    .list-unstyledC {
        text-align: left;
        list-style: none;
        font-size: 16px;
        color: #d1d1d1;
        padding-left: 0;
        text-decoration: underline;
    }

    .hrFooterR {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 15px 0 15px 0px;
        opacity: 1;
    } 
    .infoPosition {
        position: relative;
        left: 0px;
    }
    .footerbg {
        background-color: #2f3542;
    }
    .hrFooter {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 15px 0 15px 0;
        opacity: 1;
    }
    .hrFooter1 {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 15px 0 15px 0;
        opacity: 1;
    }
}

  @media (min-width: 769px) and (max-width: 992px) {
    /* CSS pravila za medium ekrane */
    .hrFooter {
    color: #d1d1d1;
    border-top: 1px solid;
    width: 100%;
    margin: 15px 0 15px 0;
    opacity: 1;
    }
    .hrFooter {
    color: #d1d1d1;
    border-top: 1px solid;
    width: 100%;
    margin: 15px 0 15px 0;
    opacity: 1;
    }
    .hrFooterC {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 8px 0 15px 21px;
        opacity: 1;
    }
    .footerMT {
        margin-top: 21px;
    }
    .hrFooterR {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 15px 0 15px 10px;
        opacity: 1;
    }
    .h6FooterL {
        font-size: 23px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: left;
        color: #d1d1d1;
    }
    .h6FooterC {
        font-size: 23px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        color: #d1d1d1;
    }
}

  @media (min-width: 993px) and (max-width: 1200px) {
    /* CSS pravila za large ekrane */
    .hrFooterR {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 233px;
        margin: 15px 0 15px 45px;
        opacity: 1;
    }
    .footerMT {
        margin-top: 18px;
    }
    .hrFooter {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 100%;
        margin: 15px 0 15px 0;
        opacity: 1;
    }
    .h6FooterC {
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        color: #d1d1d1;
        margin-bottom: 7px;
    }
    .footerMT {
        margin-top: 18px;
    }
  }

  @media (min-width: 1201px) {
    /* CSS pravila za extra large ekrane */
    .hrFooterR {
        color: #d1d1d1;
        border-top: 1px solid;
        width: 305px;
        margin: 15px 0 15px 45px;
        opacity: 1;
    } 
    .footerMT {
        margin-top: 48px;
    }
}
  

.my-link-footer {
    color: #d1d1d1;
    text-decoration: none;
  }
  
  .my-link-footer:hover {
    color: darkorange;
    transition: all .3s;
  }