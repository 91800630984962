.dropdown-menu {
  position: absolute !important;
  top: 100%;
  display: flex;
  left: 0;
  transform: translateX(-50%);
  z-index: 9999; /* Dodajte ovaj stil samo ako meni div bude prekriven drugim elementima */
}

/* background: linear-gradient( rgba(53, 53, 53, 0.927), rgba(58, 58, 58, 0.9) ); */
.dropdown-content {
  left: 0;
  top: 75px;
  z-index: 14;
  transition: .3s ease-in-out;
  border-radius: 0;
  overflow: hidden;
  background: rgba(47, 53, 66, 1);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: fixed; /* Promenite "absolute" u "fixed" */
  transform: translate3d(0, 0, 0); /* Dodajte ovu liniju */
}

.overlay-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  position: relative;
}

.overlay-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 172px;
  bottom: 0;
  width: 2px; /* Širina bočnog bordera */
  background-color: #fff;
}

.overlay-image {
  width: 120px;
  height: 120px;
}

.overlay-text {
  margin-top: 10px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.overlay-links {
  margin-top: 30px;
}

.overlay-links a {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all .3s;
}

.overlay-links a:hover {
  color: darkorange;
}

.my-link {
  color: #fff;
  text-decoration: none;
}

.my-link:hover {
  color: darkorange;
  transition: all .3s;
}

@media (max-width: 576px) {
  /* CSS pravila za extra small ekrane */
  .icon-nav {
    display: none;
  }

  .overlay-item::before {
    display: none;
  }
  
  .overlay-links {
    margin-top: 0px;
    display: none;
  }

  .overlay-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px;
    color: #fff;
    position: relative;
  }

  .dropdown-content {
    left: 0;
    top: 145px;
    z-index: 14;
    transition: .3s ease-in-out;
    border-radius: 0;
    overflow: hidden;
    background: rgba(47, 53, 66, 1);
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: fixed;
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 230px) and (max-width: 768px) {
  /* CSS pravila za small ekrane
  .icon-nav {
    display: none;
  }

  .overlay-item::before {
    display: none;
  }
  
  .overlay-links {
    margin-top: 0px;
    display: none;
  }

  .overlay-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px;
    color: #fff;
    position: relative;
  }

  .dropdown-content {
    left: 0;
    top: 145px;
    z-index: 14;
    transition: .3s ease-in-out;
    border-radius: 0;
    overflow: hidden;
    background: rgba(47, 53, 66, 1);
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: fixed;
    transform: translate3d(0, 0, 0);
  }

  .justify-content-center {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  } */
  .dropdown-menu {display: none;}
}

@media (min-width: 769px) and (max-width: 992px) {
  /* CSS pravila za medium ekrane */
  /* .icon-nav {
    display: none;
  }

  .overlay-item::before {
    display: none;
  }
  
  .overlay-links {
    margin-top: 0px;
    display: none;
  }

  .overlay-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px;
    color: #fff;
    position: relative;
  }

  .dropdown-content {
    left: 0;
    top: 145px;
    z-index: 14;
    transition: .3s ease-in-out;
    border-radius: 0;
    overflow: hidden;
    background: rgba(47, 53, 66, 1);
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: fixed;
    transform: translate3d(0, 0, 0);
  }

  .justify-content-center {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }  */
  .dropdown-menu {
    display: none;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  /* CSS pravila za large ekrane */
}

@media (min-width: 1201px) {
  /* CSS pravila za extra large ekrane */
  
}