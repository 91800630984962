.titleHandlerVitrine {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-287%, -29%) !important;
    z-index: 1 !important;
    color: #fff !important;
  }

@media (max-width: 576px) {
    .h3TapaciraniNamestaj {
      font-size: 27px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerVitrine {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-99%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    }
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 577px) and (max-width: 768px) { 
    .h3TapaciraniNamestaj {
      font-size: 28px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerVitrine {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-89%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .h3TapaciraniNamestaj {
      font-size: 30px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerVitrine {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-164%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 993px) and (max-width: 1200px) {
    .h3TapaciraniNamestaj {
      font-size: 30px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerVitrine {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-235%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
    .CardImg {
      width: 100%;
      height: auto !important;
      transition: transform 0.5s ease-in-out;
    }
  }
  
  
  @media (min-width: 1201px) and (max-width: 1462px) {
    .h3TapaciraniNamestaj {
      font-size: 32px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: left;
      color: #fff;
    }
    .titleHandlerVitrine {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-303%, -15%) !important;
      z-index: 1 !important;
      color: #fff !important;
    } 
  }