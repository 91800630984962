/* #2f3542 */

.h2Proizvodi {    
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
}

.hrProizvodi {
    color: #2f3542;
    border-top: 2px solid;
    width: 350px;
    margin: 15px 0 0 0 ;
    opacity: 1;
}

.dflexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.image_slider {
    height: 150px;
    width: 150px;
}

.sliderh4{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
    margin-bottom: 15px;
}
.swiperSlideHover {
    cursor: pointer;
}
.swiperSlideHover .sliderh4{
    transition: all 0.2s;
}

.swiperSlideHover:hover .sliderh4 {
    color: #ff8c00 !important;
    }
  

.swiper-free-mode>.swiper-wrapper {
    transition-timing-function: ease-out;
    /* margin: 0 auto; */
    margin: 0 0 50px 0 !important;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #2f3542 !important;
}
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  /* ZASTO IZABRATI NAS */
  .containerChoose {
   display: flex;
   justify-self: center;
   align-items: center;
   width: 100%;
   height: auto;
   background-color: #2f3542;
   margin-top: 15px;
  }


  .containerChooseImage {
    margin: 40px;
    transition: all .2s;
}

.image-container{
    position: relative;
    display: inline-block;
}

.image-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000A1;
    opacity: 0.65;
    z-index: 1;
    transition: opacity 0.2s ease;
  }
  
  
  .image-text {
    position: absolute;
    bottom: 200px;
    left: 30px;
    color: white;
    z-index: 2; /* Pomicanje teksta iznad pseudo-elementa */
    padding: 5px;
    opacity: 1; /* Dodajte ovu liniju kako biste postavili punu vidljivost teksta */
  }
  
  .image-text1 {
    position: absolute;
    bottom: 40px;
    left: 30px;
    color: white;
    z-index: 2; /* Pomicanje teksta iznad pseudo-elementa */
    padding: 5px;
    opacity: 1; /* Dodajte ovu liniju kako biste postavili punu vidljivost teksta */
  }

  .image-text12 {
    position: absolute;
    bottom: 64px;
    left: 30px;
    color: white;
    z-index: 2; /* Pomicanje teksta iznad pseudo-elementa */
    padding: 5px;
    opacity: 1; /* Dodajte ovu liniju kako biste postavili punu vidljivost teksta */
  }

  .textImageh3 {
    font-weight: 600;
  }

  .textImageP {
    font-size: 16px;
  }

  /* ponudaStolice */

  .stoliceImg {
    width: 100%;
    height: 550px;
    object-fit: contain;
  }

  .containerStolice {
    width: 100%;
    height: inherit;
  }

  .h3Stolice {
    font-size: 27px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 65px;
    text-align: center;
    color: #2f3542; 
  }

  .pStolice{
    font-size: 18px;
    margin-top: 35px;
    text-align: left;
    color: #2f3542; 
  }

.containerGalery {
  position: relative; 
  cursor: pointer;
} 
.overlayGalery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* zatamnjivanje slike */
  opacity: 0;
  transition: opacity 0.4s ease;
}

.containerGalery:hover .overlayGalery {
  opacity: 1;
}

.textGalery {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
 
.SpanGallery {
  color: darkorange;
}

.GalleryDiv {
  background-color: #2f3542;
  width: 100%;
  height: 350px;
  margin-top: -200px;
}

 /* klub sto */
.h3KlubSto {
  font-size: 27px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 90px;
  text-align: center;
  color: #2f3542; 
}

.klubstoImg {
  width: 100%;
  height: 450px;
  object-fit: contain;
}


/* kontakt  */
#kontakt {
  text-align: center;
  background-image: linear-gradient(
    rgba(53, 53, 53, 0.427),
    rgba(58, 58, 58, 0.4)
  ), url(../../namestaj/_mzf2101079_PSMS\ copy.jpg);

  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
}

.kontaktHolder {
  position: relative;
}

.kontNaslov {
  position: absolute;
  font-size: 29px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  top: 170px;
  left: 100px;
  color: #fff;  
}

.kontNaslov1 {
  position: absolute;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  top: 215px;
  left: 100px;
  color: #fff;  
}


.kontaktButton {
  position: absolute;
  top: 250px;
  left: 100px;
  padding: 12px 30px;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid #fff;
  transition: all 0.3s;
}


.kontaktButton:hover {
  background-color: darkorange;
  border: 2px solid darkorange;
}

@media (max-width: 280px) {
  .logo {
    width: 170px;
    height: 25px;
  }
  .carousel-caption h3 {
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 25px;
  }
  .hrProizvodi {
    color: #2f3542;
    border-top: 2px solid;
    width: 225px;
    margin: 15px 0 0 0;
    opacity: 1;
  }
  .txtMain {
    display: none;
  }
  .h2Proizvodi {
  font-size: 22px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
}
}

@media (max-width: 360px) { 
  .image_slider {
    height: 100px;
    width: 100px;
}
  .carousel-caption {
    bottom: 5em !important;
  }
  .image-text {
    position: absolute;
    bottom: 147px !important;
    left: 14px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
}
.textImageP {
  font-size: 13px !important;
}
.image-text1 {
  position: absolute;
  bottom: -10px !important;
  left: 14px !important;
  color: white;
  z-index: 2;
  padding: 5px;
  opacity: 1;
  }

  .textImageh3 {
    font-weight: 400;
    font-size: 20px;
  }
  .image-text12 {
    position: absolute;
    bottom: 13px;
    left: 14px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .kontNaslov {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 159px !important;
    left: 0px !important;
    color: #fff;
}
.kontNaslov1 {
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  top: 203px !important;
  left: 4px !important;
  color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 252px !important;
    left: 115px !important;
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
}
 .kontaktButton {
  position: absolute;
  top: 252px !important;
  left: 115px !important;
  padding: 10px 15px;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid #fff;
  transition: all 0.3s;
  }
  .hrFooter {
    color: #d1d1d1;
    border-top: 1px solid;
    width: auto !important;
    margin: 15px 0 15px 0;
    opacity: 1;
  }
  .hrFooterR {
    color: #d1d1d1;
    border-top: 1px solid;
    width: auto !important;
    margin: 15px 0 15px 0px;
    opacity: 1;
  }
}

@media (min-width: 361px) and (max-width: 390px) { 
  .image_slider {
    height: 100px;
    width: 100px;
  }
  .carousel-caption {
    bottom: 5em !important;
}
  .image-text {
    position: absolute;
    bottom: 147px !important;
    left: 7px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
}
.textImageP {
  font-size: 13px !important;
}
.image-text1 {
  position: absolute;
  bottom: -10px !important;
  left: 7px !important;
  color: white;
  z-index: 2;
  padding: 5px;
  opacity: 1;
  }

  .textImageh3 {
    font-weight: 400;
    font-size: 20px;
  }
  .image-text12 {
    position: absolute;
    bottom: 13px;
    left: 7px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .kontNaslov {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 159px !important;
    left: 0px !important;
    color: #fff;
}
.kontNaslov1 {
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  top: 203px !important;
  left: 4px !important;
  color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 252px !important;
    left: 122px !important;
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
}

  .hrFooter {
    color: #d1d1d1;
    border-top: 1px solid;
    width: auto !important;
    margin: 15px 0 15px 0;
    opacity: 1;
  }
  .hrFooterR {
    color: #d1d1d1;
    border-top: 1px solid;
    width: auto !important;
    margin: 15px 0 15px 0px;
    opacity: 1;
  }
}


@media (min-width: 390px) and (max-width: 420px) { 
  .image_slider {
    height: 100px;
    width: 100px;
  }
  .carousel-caption {
    bottom: 5em !important;
  }

  .image-text {
    position: absolute;
    bottom: 147px !important;
    left: 15px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
}
.textImageP {
  font-size: 14px !important;
}
.image-text1 {
  position: absolute;
  bottom: -6px !important;
  left: 15px !important;
  color: white;
  z-index: 2;
  padding: 5px;
  opacity: 1;
  }

  .textImageh3 {
    font-weight: 400;
    font-size: 20px;
  }
  .image-text12 {
    position: absolute;
    bottom: 13px;
    left: 15px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .kontNaslov {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 159px !important;
    left: 0px !important;
    color: #fff;
}
.kontNaslov1 {
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  top: 203px !important;
  left: 4px !important;
  color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 252px !important;
    left: 128px !important;
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
}

  .hrFooter {
    color: #d1d1d1;
    border-top: 1px solid;
    width: auto !important;
    margin: 15px 0 15px 0;
    opacity: 1;
  }
  .hrFooterR {
    color: #d1d1d1;
    border-top: 1px solid;
    width: auto !important;
    margin: 15px 0 15px 0px;
    opacity: 1;
  }
}


@media (min-width: 420px) and (max-width: 520px) { 
  .image_slider {
    height: 100px;
    width: 100px;
}
  .carousel-caption {
    bottom: 5em !important;
  }
  .image-text {
    position: absolute;
    bottom: 189px !important;
    left: 23px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .image-text1 {
    position: absolute;
    bottom: 5px !important;
    left: 23px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .image-text12 {
    position: absolute;
    bottom: 21px !important;
    left: 23px !important;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .kontNaslov1 {
    position: absolute;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 198px !important;
    left: 21px;
    color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 224px !important;
    left: 21px;
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
  }
}


@media (min-width: 521px) and (max-width: 576px) { 
  .carousel-caption {
    bottom: 5em !important;
  }
  .textImageP {
    font-size: 20px !important;
    font-weight: 500 !important;
  }

  .image-text1 {
    position: absolute;
    bottom: 27px !important;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  
  .image-text12 {
    position: absolute;
    bottom: 34px !important;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
}

@media (max-width: 576px) {
  /* CSS pravila za extra small ekrane */
  .h2Proizvodi {
    font-size: 22px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #2f3542;
  }

  .h3Stolice {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 65px;
    text-align: center;
    color: #2f3542;
}

.h3KlubSto {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 90px;
  text-align: center;
  color: #2f3542;
}
  
  .hrProizvodi {
    color: #2f3542;
    border-top: 2px solid;
    width: 305px;
    margin: 15px 0 0 0;
    opacity: 1;
  }
    .image-text {
      position: absolute;
      bottom: 237px;
      left: 30px;
      color: white;
      z-index: 2;
      padding: 5px;
      opacity: 1;
  }
  .textImageP {
    font-size: 17px;
  }
  .image-text1 {
    position: absolute;
    bottom: 50px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .pStolice {
    font-size: 18px;
    margin-top: 35px;
    text-align: left;
    margin-left: 20px;
    color: #2f3542;
  }
  .kontNaslov {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 159px;
    left: 21px;
    color: #fff;
  }

  .kontNaslov1 {
    position: absolute;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 183px;
    left: 21px;
    color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 212px;
    left: 21px;
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  /* CSS pravila za small ekrane */
    .image-text {
      position: absolute;
      bottom: 373px;
      left: 30px;
      color: white;
      z-index: 2;
      padding: 5px;
      opacity: 1;
  }
  .textImageh3 {
    font-weight: 600;
    font-size: 34px;
  }
  .textImageP {
    font-size: 24px;
  } 
  .image-text1 {
    position: absolute;
    bottom: 124px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }  
  .image-text12 {
    position: absolute;
    bottom: 130px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  
  .pStoliceS {
    margin-left: 10px;
  }
  
  .pStoliceM {
    margin-left: 45px;
  }
  .kontNaslov1 {
    position: absolute;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 212px;
    left: 62px;
    color: #fff;
}
  .kontNaslov {
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 162px;
    left: 4px;
    color: #fff;
}
.kontaktButton {
  position: absolute;
  top: 243px;
  left: 202px;
  padding: 7px 20px;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid #fff;
  transition: all 0.3s;
  }
}

@media (min-width: 578px) and (max-width: 710px) {
  .textImageh3 {
    font-weight: 600;
    font-size: 29px;
  }
  .image-text {
    position: absolute;
    bottom: 263px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
}
.image-text1 {
  position: absolute;
  bottom: 47px;
  left: 30px;
  color: white;
  z-index: 2;
  padding: 5px;
  opacity: 1;
}
.textImageP {
  font-size: 21px;
}
.image-text12 {
  position: absolute;
  bottom: 71px;
  left: 30px;
  color: white;
  z-index: 2;
  padding: 5px;
  opacity: 1;
}
}

@media (min-width: 768px) and (max-width: 992px) {
  /* CSS pravila za medium ekrane */
  
  .carousel-caption {
    bottom: 13em !important;
  }

  .mdContainer50 {
    flex: 0 0 auto;
    width: 50%;
  }
  .textImageh3 {
    font-weight: 600;
    font-size: 19px;
  }
  .textImageP {
    font-size: 13px;
  }
  .image-text1 {
    position: absolute;
    bottom: 3px;
    left: 10px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
}
  .image-text {
    position: absolute;
    bottom: 153px;
    left: 10px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .image-text12 {
    position: absolute;
    bottom: 22px;
    left: 10px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .kontNaslov {
    position: absolute;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 145px;
    left: 5px;
    color: #fff;
  }
  .kontNaslov1 {
    position: absolute;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 209px;
    left: 112px;
    color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 250px;
    left: 281px;
    padding: 10px 30px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
  }
 
}



@media (min-width: 906px) and (max-width: 991px) {
  /* CSS pravila za large ekrane */
  .textImageh3 {
    font-weight: 600;
    font-size: 24px;
}
.image-text {
    position: absolute;
    bottom: 185px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .image-text1 {
    position: absolute;
    bottom: 34px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .textImageP {
    font-size: 14px;
  }
  .image-text12 {
    position: absolute;
    bottom: 40px;
    left: 30px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  /* CSS pravila za large ekrane */
  
  .image-text1 {
    position: absolute;
    bottom: -5px;
    left: 8px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .textImageP {
    font-size: 10px;
  }
  .textImageh3 {
    font-weight: 600;
    font-size: 19px;
  }
  .image-text {
    position: absolute;
    bottom: 114px;
    left: 8px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .image-text12 {
    position: absolute;
    bottom: 0px;
    left: 8px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .pStoliceM {
    margin-left: 20px;
  }
  
  .pStoliceS {
    margin-left: 66px;
  }
  .kontNaslov {
    position: absolute;
    font-size: 29px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 170px;
    left: 35px;
    color: #fff;
  }
  .kontNaslov1 {
    position: absolute;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    top: 212px;
    left: 35px;
    color: #fff;
  }
  .kontaktButton {
    position: absolute;
    top: 249px;
    left: 35px;
    padding: 12px 30px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #fff;
    transition: all 0.3s;
  }
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: #fff;
  background-color: transparent;
  border: 2px solid white;
}


@media (min-width: 1080px) and (max-width: 1200px) {
  .carousel-caption {
    bottom: 15em;
}
  .textImageh3 {
    font-weight: 600;
    font-size: 24px;
  }
  .image-text1 {
    position: absolute;
    bottom: 10px;
    left: 8px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .image-text12 {
    position: absolute;
    bottom: 17px;
    left: 8px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }
  .textImageP {
    font-size: 11px;
  }
}

@media (min-width: 1201px) and (max-width: 1462px) {
  /* CSS pravila za extra large ekrane */
  .textImageh3 {
    font-weight: 600;
    font-size: 25px;
  }
  .textImageP {
    font-size: 14px;
  }
  .image-text1 {
    position: absolute;
    bottom: 2px;
    left: 15px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  } 
  .image-text {
    position: absolute;
    bottom: 155px;
    left: 15px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  } 
  .image-text12 {
    position: absolute;
    bottom: 4px;
    left: 15px;
    color: white;
    z-index: 2;
    padding: 5px;
    opacity: 1;
  }  
  .pStoliceM {
    margin-left: 60px;
  }
  .pStoliceS {
    margin-left: 30px;
  }
}


@media (min-width: 1402px) and (max-width: 1460px) {
  .pStoliceM {
    margin-left: 100px;
  }
  .pStoliceS {
    margin-left: 75px;
  }
}

@media (min-width: 1462px)  {
  .pStoliceM {
    margin-left: 100px;
  }
  
  .pStoliceS {
    margin-left: 75px;
  }
}

@media (min-width: 415px) and (max-width: 415px) {
.carousel-caption {
    bottom: 17em !important;
  }
}


